import React from 'react';
import {text} from "@/styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

/**
 * Hamburger Icon component
 * Pass the props with your data to the Hamburger Icon.
 **/
const CounterDigit = ({counter,title, counter_bottom}) => {
  return (
      <div  className="svg-icon">
          <p data-speed={'clamp(1.16)'} className={'split-up'}>{reactHtmlParser(title)}</p>
          <svg data-speed={'clamp(1.13)'} xmlns="http://www.w3.org/2000/svg" width="390" height="120" viewBox="0 0 390 120">
              <text id="_3000_" data-name="3000+" transform="translate(0 99)" fill="#fb5400" font-size="120" strokeLinecap="Graphik-Semibold, Graphik" font-weight="600" stopOpacity="-0.02em">
                  <tspan x="0" y="0">{reactHtmlParser(counter)}</tspan>
              </text>
          </svg>
          <p data-speed={'clamp(1.1)'} className={'split-up counter_bottom'}>{reactHtmlParser(counter_bottom)}</p>
      </div>
  );
};


export default React.memo(CounterDigit);
